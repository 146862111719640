import { Guid } from "typescript-guid";
import { IRemoveAppliedResumeFile } from "./abstraction";
import Session from 'supertokens-auth-react/recipe/session';
import { DefaultService, OpenAPI } from "../../apiClients/getItJobsResumeApiClient";
import { inject, injectable } from "inversify";
import * as config from "../../config";

@injectable()
export class AppliedResumeFilesRemover implements IRemoveAppliedResumeFile {
    @inject(config.Types.AppliedResumeFilesRetrieverSettings)
    private readonly _appliedResumeFilesApiSettings!: config.IKeepAppliedResumeFilesApiData;

    async remove(applicationId: Guid): Promise<void> {
        const jwt  = await Session.getAccessToken();
    
        OpenAPI.BASE = this._appliedResumeFilesApiSettings.appliedResumeFilesApiUrl;
        OpenAPI.TOKEN = jwt;

        await DefaultService.deleteDelete(applicationId.toString());
    }    
}