import React, {Component} from 'react';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import { resolve } from 'inversify-react';
import Select from 'react-select';
import * as SS from '../../../services/Admin/SystemSettings';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import './SystemSettings.css';
import { toast } from 'react-toastify';
import { Guid } from 'typescript-guid';

class SystemSettings extends Component {
    private readonly jsonEditorRef: React.RefObject<any>;
    private jsonEditor: JSONEditor | null = null;

    @resolve(SS.Types.GetSystemSettingsService)
    private readonly _systemSettingsGetterService!: SS.IGetSystemSettingsService

    @resolve(SS.Types.UpdateSystemSettingsService)
    private readonly _systemSettingsUpdaterService!: SS.IUpdateSystemSettingsService

    state = {
        allSettings: [] as { label: string; value: any; version: Guid }[],
        selectedSetting: {} as { label: string, value: any, version: Guid }
    }

    constructor(props: any){
        super(props);

        this.handleJsonEditorChange = this.handleJsonEditorChange.bind(this);
        this.changeVisibleSetting = this.changeVisibleSetting.bind(this);
        this.save = this.save.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setStateSynchronous = this.setStateSynchronous.bind(this);

        this.jsonEditorRef = React.createRef();
    }

    setStateSynchronous(stateUpdate: {}) : Promise<void> {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    async componentDidMount(): Promise<void>{
        this.jsonEditor = new JSONEditor(this.jsonEditorRef.current, { onChange: this.handleJsonEditorChange });

        await this.refresh();
    }

    componentWillUnmount(): void {
        if(this.jsonEditor !== null) {
            this.jsonEditor.destroy();
        }
    }

    handleJsonEditorChange = () => {
        if (this.jsonEditor !== null) {
            const json = this.jsonEditor.get();
            
            const setting = { label: this.state.selectedSetting.label, value: json, version: this.state.selectedSetting.version };

            this.setState({selectedSetting: setting});
        }
    }

    changeVisibleSetting = (setting: {label: string, value: any, version: Guid} | null ) => {
        this.setState({selectedSetting: setting});
        this.jsonEditor?.set(setting?.value);
        if (typeof setting?.value === 'string') {
            this.jsonEditor?.setMode('text');
        }
        else {
            this.jsonEditor?.setMode('tree');
            this.jsonEditor?.expandAll();
        }
    }

    async refresh(): Promise<void> {
        const systemSettings = await this._systemSettingsGetterService.getSystemSettings();
        const allSettings = systemSettings.map(sn => { return { label: sn.name, value: sn.value, version: sn.version } });

        await this.setStateSynchronous({allSettings: allSettings});
        this.changeVisibleSetting(allSettings[0]);
    }

    async save(): Promise<void> {
        try {
            const currentSetting = this.state.selectedSetting;
            await this._systemSettingsUpdaterService.updateSetting({ name: currentSetting.label, value: currentSetting.value, version: currentSetting.version });

            await this.refresh();

            const setting = this.state.allSettings.find(s => s.label === currentSetting.label);
            this.changeVisibleSetting(setting!);

            toast.success('Zapisano ustawienia');
        }
        catch (e) {
            toast.error('Błąd podczas zapisywania ustawień');
        }
    }

    render() {
        return(
            <>
                <Header />

                <div className="page-content bg-white">
                    <div className="content-block">
                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container">
                                <div className="text-center">
                                    <h2>System Settings</h2>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                                        Ustawienie: <Select options={this.state.allSettings} closeMenuOnSelect={true} onChange={(val) => { if (val === undefined) { return; } else { this.changeVisibleSetting({ label: val!.label, value: val!.value, version: val!.version }); } } } value={this.state.selectedSetting} />
                                        <hr />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12" style={{height: '100%'}}>
                                        <div ref={this.jsonEditorRef} />
                                    </div>
                                    {
                                        this.state.selectedSetting &&
                                        <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 text-right mt-2">
                                            <button className="btn site-button" onClick={this.save}>Zapisz</button>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </>);
    }
}

export default SystemSettings;