import React, { Component } from 'react';
import * as JobSearch from '../../services/JobSearch';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { resolve } from 'inversify-react';
import './Jobsearchform.css';
import Accordion from 'react-bootstrap/Accordion'
import { Card } from 'react-bootstrap';
import { roundNumber } from '../../extensions/mathExtensions';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { convertEnumGeneric } from '../../extensions/enumsExtensions';
import { ProtectedComponent } from './ProtectedComponent';
import { ProtectionRoles } from './ProtectedBase';
import SearchTextTags from './SearchTextTags';

const animatedComponents = makeAnimated();

interface IKeepSearchFormState {
	searchTextTags: { label: string, value: string }[],
	allAvailableCategories: { label: string, value: string }[],
	allAvailableLocations: { 
		label: string, 
		value: string | null, 
		options: any
	}[],
	allAvailableSeniorityLevels: {label: string, value: string}[],
	wageRange: {
		min: number,
		max: number
	},
	minMaxWageRange: {
		min: number,
		max: number
	}
	currency: string,
	allAvailableCurrencies: {
		label: string,
		value: string
	}[],
	allAvailableCurrenciesWithMinMaxWageRange: JobSearch.IAmCurrencyWithMinMax[],
	allAvailableJobSources: {label: string, value: string}[],
	selectedCategories: JobSearch.IAmJobSearchJobCategory[],
	selectedLocations: string[],
	selectedSeniorityLevels: JobSearch.IAmJobSeniorityLevel[],
	searchOffersWithoutSalary: boolean,
	selectedJobSources: JobSearch.IAmJobSource[],

	firstTimeRender: boolean,

	onDataRetrieved: (jobs: JobSearch.IAmJobFound[], filterCurrency: string) => void
	onSearchStarted: () => void
}

export interface JobSearchFormOptions {
	onDataRetrieved: (jobs: JobSearch.IAmJobFound[], filteredCurrency: string) => void
	onSearchStarted: () => void
}

class JobSearchform extends Component <JobSearchFormOptions, IKeepSearchFormState>{
	@resolve(JobSearch.Types.SearchJobsService)
	private readonly _searchJobsService!: JobSearch.ISearchForJobs;

	@resolve(JobSearch.Types.LocationsForJobSearchService)
	private readonly _jobsLocationsService!: JobSearch.IGetLocationsForJobSearch;

	@resolve(JobSearch.Types.CurrenciesWithMinMaxForJobSerachService)
	private readonly _currenciesWithMinMaxService!: JobSearch.IGetCurrenciesWithMinMaxForJobSearch;
	
	private readonly defaultWageMin: number = 0; 
	private readonly defaultWageMax: number = 10_000_000; 
	private readonly defaultCurrency: string = "PLN"; //todo: unhardcode this!

	public constructor(props: JobSearchFormOptions) {
		super(props);

		this.state = { 
			searchTextTags: [],
			onDataRetrieved: props.onDataRetrieved,
			onSearchStarted: props.onSearchStarted,
			selectedCategories: [],
			selectedLocations: [],
			selectedSeniorityLevels: [],
			allAvailableCategories: Object.values(JobSearch.IAmJobSearchJobCategory).map(category => {
				return {
					label: category.toString(),
					value: category.toString()
				}
			}),
			allAvailableLocations: [],
			allAvailableSeniorityLevels: Object.values(JobSearch.IAmJobSeniorityLevel).map(seniorityLevel => {
				return {
					label: seniorityLevel.toString(),
					value: seniorityLevel.toString()
				}
			}),
			allAvailableCurrencies: [],
			allAvailableCurrenciesWithMinMaxWageRange: [],
			allAvailableJobSources: Object.values(JobSearch.IAmJobSource).map(jobSource => { return { label: jobSource.toString(), value: jobSource.toString() } }),
			wageRange: {
				min: this.defaultWageMin,
				max: this.defaultWageMax 
			},
			minMaxWageRange: {
				min: this.defaultWageMin,
				max: this.defaultWageMax 
			},
			currency: this.defaultCurrency, 
			searchOffersWithoutSalary: true,
			selectedJobSources: [],
			firstTimeRender: true
		};

		this.onSeachClick = this.onSeachClick.bind(this);
		this.changeCurrency = this.changeCurrency.bind(this);
		this.changeCurrencyWithMinMax = this.changeCurrencyWithMinMax.bind(this);
		this.setStateSynchronous = this.setStateSynchronous.bind(this);
		this.retrieveJobs = this.retrieveJobs.bind(this);
	}

	async componentDidMount() {
		try
		{
			const [availableLocations, availableCurrenciesWithMinAndMax] = await Promise.all([this._jobsLocationsService.get(), this._currenciesWithMinMaxService.get()]);
			
			const tenMostUsedLocations = availableLocations.splice(0, 10).sort();
			const theMostUsedLocationsOption = {
				label: "Najczęstsze lokalizacje", //todo: fudged! translation!
				value: null,
				options: tenMostUsedLocations.map(val => {
					return {
						label: val,
						value: val
					}
				})
			};

			const restOfLocationOptions = {
				label: "Pozostałe lokalizacje", //todo: fudged! translation!
				value: null,
				options: availableLocations.sort().map(val => {
					return {
						label: val,
						value: val
					}
				})
			};

			const allAvailableAndGroupedLocations = [
				theMostUsedLocationsOption,
				restOfLocationOptions
			];

			this.setState({ 
				allAvailableLocations: allAvailableAndGroupedLocations, 
				allAvailableCurrenciesWithMinMaxWageRange: availableCurrenciesWithMinAndMax,
				allAvailableCurrencies: availableCurrenciesWithMinAndMax.map(val => {
					const retVal = {
						label: val.currency,
						value: val.currency
					};

					return retVal;
				}) 
			});

			var currencyWithMinAndMax = availableCurrenciesWithMinAndMax.find(val => val.currency === this.state.currency);
			this.changeCurrencyWithMinMax(currencyWithMinAndMax!);
			await this.setInitialCurrencyWithMinMax(currencyWithMinAndMax!);

			await this.retrieveJobs();
		}
		finally{
			this.setState({firstTimeRender: false});
		}
	}

	private async onSeachClick(e: any) {
		this.state.onSearchStarted();
		e.preventDefault();
		
		this.retrieveJobs();
	}

	private async retrieveJobs() {
		const jobsRetrieved = await this._searchJobsService.search(
			this.state.searchTextTags.map(val => val.value),
			JobSearch.IAmJobSearchLanguage.Unknown, 
			this.state.selectedCategories, 
			this.state.currency, 
			this.state.selectedLocations, 
			this.state.selectedLocations.includes("Remote"), //todo: fudged!
			this.state.selectedSeniorityLevels,
			this.state.wageRange.min,
			this.state.wageRange.max,
			this.state.searchOffersWithoutSalary,
			this.state.selectedJobSources
			);
		
		this.state.onDataRetrieved(jobsRetrieved, this.state.currency);
	}

	onCategoriesChange = (selectedOptions: any) => {
		const typedSelectedOptions = selectedOptions as { label: string, value: string }[];

		this.setState({selectedCategories: typedSelectedOptions.map(val => convertEnumGeneric(val.value, JobSearch.IAmJobSearchJobCategory)!)});
	}

	onLocationsChange = (selectedOptions: any) => {
		const typedSelectedLocations = selectedOptions as { label: string, value: string }[];
		this.setState({selectedLocations: typedSelectedLocations.map(val => val.value)});
	}

	onSeniorityLevelsChange = (selectedOptions: any) => {
		const typedSelectedLocations = selectedOptions as { label: string, value: string }[];
		this.setState({selectedSeniorityLevels: typedSelectedLocations.map(val => convertEnumGeneric(val.value, JobSearch.IAmJobSeniorityLevel)!)});
	}

	onJobSourcesChange = (selectedOptions: any) => {
		const typedSelectedJobSources = selectedOptions as { label: string, value: string }[];
		this.setState({selectedJobSources: typedSelectedJobSources.map(val => convertEnumGeneric(val.value, JobSearch.IAmJobSource)!)});
	}

	onCurrencyChange = (selectedOption: any) => {
		const typedSelectedCurrency = selectedOption as { label: string, value: string };
		this.changeCurrency(typedSelectedCurrency.value);
	}

	changeCurrency(currency: string) {
		const newCurrencyWithMinAndMax = this.state.allAvailableCurrenciesWithMinMaxWageRange.filter(val => val.currency === currency)[0] || {currency: currency, max: this.defaultWageMax, min: this.defaultWageMin};
		this.changeCurrencyWithMinMax(newCurrencyWithMinAndMax);
	}

	changeCurrencyWithMinMax(currencyWithMinMax: JobSearch.IAmCurrencyWithMinMax) {
		this.setState({minMaxWageRange: {max: currencyWithMinMax.max, min: 0}, currency: currencyWithMinMax.currency, wageRange: {min: 0, max: currencyWithMinMax.max} });
	}

	async setInitialCurrencyWithMinMax(currencyWithMinMax: JobSearch.IAmCurrencyWithMinMax): Promise<void> {
		await this.setStateSynchronous({minMaxWageRange: {max: currencyWithMinMax.max, min: 0}, currency: currencyWithMinMax.currency, wageRange: {min: 0, max: currencyWithMinMax.max} });
	}

	setStateSynchronous(stateUpdate: {}) : Promise<void> {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

	render(){

		if(this.state.firstTimeRender)
		{
			return (<></>);
		}

		return(
			
			<form onSubmit={(e) => { e.preventDefault(); this.onSeachClick(e); }}>
				<div className="job-search-form container">
					<div className="row" style={{minHeight: 50}}>
						<div className="input-group">
							<SearchTextTags 
								onTypingFinished={async (searchTextTags) => { 
									await this.setStateSynchronous({searchTextTags: searchTextTags.map(val => { return { label: val, value: val } }) });
									await this.retrieveJobs();
									}}

								onSearchTextTagsChange={async (searchTextTags) => {
									await this.setStateSynchronous({searchTextTags: searchTextTags.map(val => { return { label: val, value: val } }) });
								}}

								searchTextTags={this.state.searchTextTags.map(val => val.value)}
							/>
						</div>
					</div>
					<Accordion defaultActiveKey="0">
						<Card className="row">
							<Accordion.Item eventKey="0">
								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<div className="row">
											<div className="input-group form-control row-xs-100-percent-height row-md-100-percent-height">

												<Select options={this.state.allAvailableCategories} isMulti 
													components={animatedComponents} closeMenuOnSelect={false} 
													onChange={this.onCategoriesChange} className="col-xs-12 col-md-12 col-lg-3" placeholder="Kategorie" 
													classNamePrefix="categoriesSelect" />

												<Select options={this.state.allAvailableLocations} isMulti 
													components={animatedComponents} closeMenuOnSelect={false} 
													onChange={this.onLocationsChange} className="col-xs-12 col-md-12 col-lg-3" placeholder="Lokalizacje"
													classNamePrefix="locationsSelect" />

												<Select options={this.state.allAvailableSeniorityLevels} isMulti 
													components={animatedComponents} closeMenuOnSelect={false} 
													onChange={this.onSeniorityLevelsChange} className="col-xs-12 col-md-12 col-lg-3" placeholder="Doświadczenie"
													classNamePrefix="seniorityLevelsSelect" />
												
												<Select options={this.state.allAvailableCurrencies} closeMenuOnSelect={true}
													onChange={this.onCurrencyChange} className="col-xs-12 col-md-12 col-lg-3 " 
													placeholder="Waluta" value={ { label: this.state.currency, value: this.state.currency} }/>
											</div>
										</div>
										<ProtectedComponent 
											roles={[ProtectionRoles.Admin, ProtectionRoles.Tester]} rolesLogicalOperator="or" 
											component={
												<div className="row">
													<div className="input-group form-control row-xs-100-percent-height row-md-100-percent-height">												
														<Select options={this.state.allAvailableJobSources} isMulti
															components={animatedComponents} closeMenuOnSelect={false}
															onChange={this.onJobSourcesChange} className="col-xs-12 col-md-12 col-lg-12 " placeholder="Źródło" 
															classNamePrefix="jobSourcesSelect" />
													</div>
												</div>
											} />
										<div className="row">
											<div className="input-group form-control row-xs-60px-height row-md-60px-height">
												<Slider range 
													min={this.state.minMaxWageRange.min} max={this.state.minMaxWageRange.max} 
													defaultValue={[this.state.minMaxWageRange.min, this.state.minMaxWageRange.max]} draggableTrack 
													marks={ [this.state.wageRange.min, this.state.wageRange.max].reduce((acc, val) => ({ ...acc, [val]: `${roundNumber(val, 2).toLocaleString(navigator.language, { style: 'decimal', useGrouping: true })} ${this.state.currency}`}), {})  }
													onChange={(newValues: any) => { var minVal = newValues[0]; var maxVal = newValues[1]; this.setState( { wageRange: { min: minVal, max: maxVal } } ) } } />
											</div>
										</div>
										<div className="row">
											<div className='col-xs-12 col-md-12 col-lg-12 input-group form-control'>
												<label className='showOffersWithoutSalaryToggle'>
													<Toggle id='doNotShowOffersWithoutWage' checked={this.state.searchOffersWithoutSalary} onChange={(e) => this.setState({searchOffersWithoutSalary: e.target.checked})}></Toggle>
													<span>&nbsp;Uwzględniaj oferty bez widełek</span>
												</label>
											</div>
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Accordion.Item>
						</Card>
					</Accordion>
					<div className="row float-right">
						<div className="input-group-prepend">
							<button type="submit" className="site-button" style={{zIndex:0}}>Szukaj</button>
						</div>
					</div>
				</div>		
			</form>
		)
	}
}
export default JobSearchform;