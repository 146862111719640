import { inject, injectable } from "inversify";
import { IConfigureTrackingData } from "./IKeepTrackingData";
import * as config from ".";
import ReactGA from "react-ga4";
import { getCookieConsents } from "../markup/Element/CookieConsentsOverlay";

@injectable()
export class TrackingDataConfigurator implements IConfigureTrackingData {
    @inject(config.Types.TrackingDataSettings)
    private readonly _trackingDataSettings!: config.IKeepTrackingData;

    public Configure(): void {
        if (this._trackingDataSettings.googleMeasurementTagId !== undefined && this._trackingDataSettings.googleMeasurementTagId !== "" && window.location.hostname !== "localhost") {
            const cookieConsents = getCookieConsents();
            if (cookieConsents.analitics) {
                ReactGA.initialize(this._trackingDataSettings.googleMeasurementTagId);
            }
        }
    }
}