import DOMPurify from "dompurify";
import React from "react";

export function highlightText(text: string, searchWords: string[]) {
    if (!text || searchWords.length === 0) return text;

    let sanitizedText = DOMPurify.sanitize(text);
    const matches = findTextToMark(sanitizedText, searchWords) ?? [];

    matches.forEach(match => {
      sanitizedText = sanitizedText.replace(match, markText(match));
    });

    return sanitizedText;
  }
  
  export function markText(text: string) {
    return `<mark style='padding: 0px !important; background-color: orange'>${text}</mark>`
  }

  export const MarkComponent = (props: any) => {
    return <mark style={{ padding: 0, backgroundColor: 'orange' }}>{props.children}</mark>;
  }

  export function findTextToMark(text: string, searchWords: string[]): RegExpMatchArray | null {
    if (!text || searchWords.length === 0) { 
      return null;
    }
  
    const sanitizedText = DOMPurify.sanitize(text);
    const sanitizedSearchWords = searchWords.map(word => DOMPurify.sanitize(word));

    const regex = generateSearchWordsRegex(sanitizedSearchWords)!;

    return sanitizedText.match(regex);
  }

  export function generateSearchWordsRegex(searchWords: string[]): RegExp | null {
    if (searchWords.length === 0) {
      return null;
    }

    // Sanitize search words
    const sanitizedSearchWords = searchWords.map(word => DOMPurify.sanitize(word));

    // Escape any special regex characters in the sanitized search words
    const searchWordsEscaped = sanitizedSearchWords.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

    return new RegExp(`\\b(${searchWordsEscaped.join('|')})\\b`, 'gi');
  }

