import React, {Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import { AuthPage } from "supertokens-auth-react/ui"
import { useNavigate } from 'react-router-dom';

var bnr = require('./../../images/banner/bnr2.jpg');

function LoginPage() {
	const navigate = useNavigate();
	return (
		<div className="page-wraper">
			<Header />
			
			<div className="page-content">
				
				<div className="dez-bnr-inr overlay-black-middle bg-pt" style={{backgroundImage: `url(${bnr})`}}>
					<div className="container">
						<div className="dez-bnr-inr-entry">
							<AuthPage preBuiltUIList={[ThirdPartyPreBuiltUI, PasswordlessPreBuiltUI]} navigate={navigate} /> 
						</div>
					</div>
				</div>
				
			</div>
			<Footer />
			
		</div>	
	)
} 

export default LoginPage;