import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import * as JobSearch from '../../services/JobSearch';
import { resolve } from 'inversify-react';
import { roundNumber } from '../../extensions/mathExtensions';
import Moment from 'react-moment';
import withRouter from '../../extensions/withrouter'

import './Jobdetail.css';
import { SaveThisApplicationInHistoryModalComponent } from '../Element/SaveThisApplicationInHistoryModal';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import Alert from 'react-bootstrap/Alert';
import { JobOfferSource } from "@all-it/api.joboffers.client";

import { Helmet } from 'react-helmet-async';

import { highlightText } from '../../extensions/highlightText';
import { Guid } from 'typescript-guid';
import Session from 'supertokens-auth-react/recipe/session';

function generateWageInformation(wage: JobSearch.IAmJobWage, wageIndex: number): JSX.Element | undefined {
	const timeUnitString = formatTimeUnit(wage.timeUnit);
	const fromRounded = roundNumber(wage.valueFrom, 2);
	const toRounded = roundNumber(wage.valueTo, 2);
	
	let wageText = `${fromRounded} - ${toRounded} ${wage.currency} ${timeUnitString}`;

	if (fromRounded === toRounded) {
		if ((wage.additionalInfo?.trim() ?? '') === '' && fromRounded === 0) {
			return undefined;
		}

		if (fromRounded === 0) {
			wageText = 'Brak szczegółowych informacji o wynagrodzeniu'; //todo: fudged! translation needed!
		}
		else {
			wageText = `${fromRounded} ${wage.currency} ${timeUnitString}`;
		}
	}

	return (<div key={`singleWageText_${wageIndex}`}>{wageText} <div key={`singleWageAddInfo_${wageIndex}`}><small><small>({wage.additionalInfo})</small></small></div></div>);
}

function formatTimeUnit(timeUnit: JobSearch.IAmJobWageTimeUnit): string { //todo: fudged! SearchJobListItem contains the same method!
    //todo: fudged! translation needed and this definitely could've been done better!
    switch(timeUnit) {
        case JobSearch.IAmJobWageTimeUnit.Daily:
            return "Dziennie";
        case JobSearch.IAmJobWageTimeUnit.Hourly:
            return "Godzinowo";
        case JobSearch.IAmJobWageTimeUnit.Monthly:
            return "Miesięcznie";
        case JobSearch.IAmJobWageTimeUnit.Weekly:
            return "Tygodniowo";
        case JobSearch.IAmJobWageTimeUnit.Yearly:
            return "Rocznie";
        default:
            return "";
    }
}

interface IAmState {
	jd: JobSearch.IAmJobDescription | null;
	showSaveThisApplicationInHistoryModal: boolean;
	searchQueryTags: string[];
}

class Jobdetail extends Component<any, IAmState>{

	private readonly jobSourceToUrl: { [key in JobOfferSource]: string } = {
		[JobOfferSource.BULL_DOG_JOB]: "https://bulldogjob.pl/",
		[JobOfferSource.IT_PRACUJ]: "https://it.pracuj.pl/",
		[JobOfferSource.JUST_JOIN_IT]: "https://justjoin.it/",
		[JobOfferSource.NO_FLUFF_JOBS]: "https://nofluffjobs.com/",
		[JobOfferSource.SOLID_JOBS]: "https://solid.jobs/",
		[JobOfferSource.ALL_IT]: "https://all-it.jobs/",
		[JobOfferSource.THE_PROTOCOL]: "https://theprotocol.it/",
		[JobOfferSource.INHIRE]: "https://inhire.io/",
		[JobOfferSource.UNKNOWN]: "", // Add this line to include the UNKNOWN property
	};

	@resolve(JobSearch.Types.GetJobsService)
	private readonly _getJobsService!: JobSearch.IGetJobs;

	public constructor(props: any) {
		super(props);

		this.state = {jd: null, showSaveThisApplicationInHistoryModal: false, searchQueryTags: []};

		this.resolveJobSourceToUrl = this.resolveJobSourceToUrl.bind(this);
		this.showSaveThisApplicationInHistoryModalForLoggedUser = this.showSaveThisApplicationInHistoryModalForLoggedUser.bind(this);
	}

	private resolveJobSourceToUrl(jobSource: JobOfferSource | undefined): string {
		if (jobSource === undefined || this.jobSourceToUrl[jobSource] === undefined) {
			return "about:blank";
		}

		return this.jobSourceToUrl[jobSource];
	}

	async componentDidMount() {
		const jobId = this.props.params.jobId;

		const searchQueryTags = this.props.params.searchQueryTags;

		if (searchQueryTags !== undefined) {
			const searchQueryTagsArray = searchQueryTags.split(",").map((tag: string) => decodeURIComponent(tag));
			this.setState({searchQueryTags: searchQueryTagsArray});

			window.history.replaceState(null, "", `/job/${jobId}`);
		}

		const jd = await this._getJobsService.get(jobId);
		this.setState({jd: jd});
	}

	async showSaveThisApplicationInHistoryModalForLoggedUser() {
		if (await Session.doesSessionExist()) {
			this.setState({ showSaveThisApplicationInHistoryModal: true });
		}
	}

	render(){
		const model = this.state;

		const hasSnapshot = model.jd?.actualSnapshotUrl != undefined && model.jd?.actualSnapshotUrl != 'about:blank';

		const wageInformationAll = (model.jd?.wages.map((singleWage, index) => generateWageInformation(singleWage, index)) || []);
		const wageInformation = wageInformationAll.filter((singleWage) => singleWage != undefined);
		const wageInformationFinal = wageInformation.length > 0 ? wageInformation : [<div key={`NoInfo_${Guid.create().toString()}`}>Brak informacji</div>];

		return(
			<div className="page-wraper">
				<Helmet>
					<title>All IT Jobs - {model.jd?.positionName || ''}</title>
					<meta property="og:description" content={model.jd?.positionDescription || ''} />
					<meta property="og:title" content={model.jd?.positionName || ''} />
					<meta property="og:image" content={model.jd?.company.imgUrl || ''} />
				</Helmet>
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="content-block">
						<div className="section-full content-inner-1">
							<div className="container">
								<div className="row">
									<div className="col-lg-4">
										<div className="sticky-top">
											<div className="row">												
												<div className="col-lg-12 col-md-12 justify-content-center">
													<div className="m-b30" style={{ textAlign: "center" }}>
														{
															model.jd?.company.imgUrl != 'about:blank' && <img src={model.jd?.company.imgUrl} alt={model.jd?.company.name} />
														}
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
														<ul>
															<li>
																<i className='ti-info'></i><strong className='font-weight-700 text-black'>Źródło</strong> 
																<span className='text-black-light'>
																	<a href={this.resolveJobSourceToUrl(model.jd?.source)}>{model.jd?.source}</a>
																</span>
															</li>
															{
																(model.jd?.locations || []).length > 0 &&
																	<li>
																		<i className="ti-location-pin"></i><strong className="font-weight-700 text-black">Lokalizacja</strong>
																		<span className="text-black-light"> 
																			{model.jd?.locations.map((singleLocation, index) => (<div key={`singleLocation_${index}`}>{singleLocation}</div>))}
																		</span>
																	</li>
															}
															<li>
																<i className="ti-money"></i><strong className="font-weight-700 text-black">Wynagrodzenie</strong> 
																<div>{wageInformationFinal}</div>
															</li>
															<li>
																<i className="ti-shield"></i><strong className="font-weight-700 text-black">Seniority</strong>{model.jd?.seniorityLevels.join(", ")}
															</li>
															<li>
																<i className="ti-home"></i><strong className="font-weight-700 text-black">Firma</strong>
																{
																	model.jd?.company.url == 'about:blank' && <span>{model.jd?.company.name}</span>
																}
																{
																	model.jd?.company.url != 'about:blank' && <a href={model.jd?.company.url}>{model.jd?.company.name}</a>
																}
															</li>
														</ul>
														<div className="d-flex justify-content-center">
															<a href={model.jd?.originalUri} className="site-button mb-2 width100Percent" target='_blank' rel="noreferrer" onClick={this.showSaveThisApplicationInHistoryModalForLoggedUser}>Aplikuję!</a> {/* //todo: fudged! in other places as well! */}
														</div>
														<SessionAuth doRedirection={false} requireAuth={true} key={"LoggedInUser"}>
															<div className="d-flex justify-content-center">
																<a href="#" className="site-button mb-2 width100Percent" onClick={() => this.setState({ showSaveThisApplicationInHistoryModal: true })}>Już zaaplikowane!</a> {/* //todo: fudged! in other places as well! */}
															</div>
														</SessionAuth>
													</div>
												</div>
												{
													hasSnapshot &&
													<div className="col-lg-12 col-md-12">
														<div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
															<div className="d-flex justify-content-center">
																<a href={model.jd?.actualSnapshotUrl} className="site-button-secondry mb-2 width100Percent" target='_blank' rel="noreferrer">Zobacz screenshot oryginału</a> {/* //todo: fudged! in other places as well! */}
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
									<div className="col-lg-8">
										<div className="job-info-box">
											<h3 className="m-t0 m-b10 font-weight-700 title-head">
												<Link to={""} className="text-secondry m-r30" dangerouslySetInnerHTML={{ __html: highlightText(model.jd?.positionName || "", this.state.searchQueryTags).trim()}}></Link>
											</h3>
											<ul className="job-info">
												<li><span><small><i className="fa fa-clock-o"></i> Dodano <Moment locale="pl" fromNow>{model.jd?.dateAdded}</Moment></small></span> {/* //todo: fudged! moment should take locale from settings not hardcoded!*/}</li>
											</ul>
											{
												(model.jd?.positionDescription || '') !== '' &&
												<p className="p-t20" dangerouslySetInnerHTML={{ __html: highlightText(model.jd?.positionDescription || "", this.state.searchQueryTags).trim() }}></p>
											}
											
											{
												(model.jd?.requiredAttributes || []).length > 0 &&
												<span>
													<h5 className="font-weight-600">Wymagania</h5>
													{
														(model.jd?.source === JobOfferSource.BULL_DOG_JOB) &&
														<Alert variant='warning'>
															W przypadku ofert z tego źródła, wymagania oraz opcjonalne wymagania są pokazywane jako jedno z uwagi na brak możliwości rozróżnienia ich w źródle. 
															Prosimy o dokładne zapoznanie się z wymaganiami i opcjonalnymi wymaganiami na stronie źródłowej.
															Za utrudnienia przepraszamy.
														</Alert>
													}
													<div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
													<div className="col-12 container">
														{
															(model.jd?.requiredAttributes?.length != undefined && model.jd.requiredAttributes.length > 1) &&
															<ul className="list-bullets row">
																{model.jd?.requiredAttributes.map((singleAttribute, index) => (<li className="col-3" key={`singleAttribute_${index}`}><small dangerouslySetInnerHTML={{ __html: highlightText(singleAttribute || "", this.state.searchQueryTags).trim() }}></small></li>))}
															</ul>
														}
														{ 
															(model.jd?.requiredAttributes?.length != undefined && model.jd.requiredAttributes.length === 1) &&
															<div>
																<p className="p-t20" dangerouslySetInnerHTML={{ __html: highlightText(model.jd?.requiredAttributes[0] || "", this.state.searchQueryTags).trim() }} ></p>
																<br />
															</div>
														} 
													</div>
												</span>
											}

											{
												(model.jd?.niceToHaveAttributes || []).length > 0 &&
												<span>
													<h5 className="font-weight-600">Mile widziane</h5>
													<div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
													<div className="col-12 container">
														{
															(model.jd?.niceToHaveAttributes?.length != undefined && model.jd.niceToHaveAttributes.length > 1) &&
															<ul className="list-bullets row">
																{model.jd?.niceToHaveAttributes.map((singleNth, index) => (<li className="col-3" key={`singleNth_${index}`}><small dangerouslySetInnerHTML={{ __html: highlightText(singleNth || "", this.state.searchQueryTags).trim() }}></small></li>))}
															</ul>
														}
														{
															(model.jd?.niceToHaveAttributes?.length != undefined && model.jd.niceToHaveAttributes.length === 1) &&
															<div>
																<p className="p-t20" dangerouslySetInnerHTML={{ __html: highlightText(model.jd?.niceToHaveAttributes[0] || "", this.state.searchQueryTags).trim() }} ></p>
																<br />
															</div>
														}
														</div>
												</span>
											}

											{
												(model.jd?.dailyTasks || []).length > 0 &&
												<span>
													<h5 className="font-weight-600">Zadania / obowiązki</h5>
													<div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
													<div className="col-12 container">
														{ 
															(model.jd?.dailyTasks?.length != undefined && model.jd.dailyTasks.length > 1) &&
															<ul className="list-bullets row">
																{model.jd?.dailyTasks.map((singleTask, index) => (<li className="col-3" key={`singleTask_${index}`}><small dangerouslySetInnerHTML={{ __html: highlightText(singleTask || "", this.state.searchQueryTags).trim() }}></small></li>))}
															</ul>
														}
														{
															(model.jd?.dailyTasks?.length != undefined && model.jd.dailyTasks.length === 1) &&
															<div>
																<p className="p-t20" dangerouslySetInnerHTML={{ __html: highlightText(model.jd?.dailyTasks[0] || "", this.state.searchQueryTags).trim() }}></p>
																<br />
															</div>
														}
													</div>
												</span>
											}

											{
												(model.jd?.methodologies || []).length > 0 && 
												<span>
													<h5 className="font-weight-600">Metodologie w projekcie</h5>
													<div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
													<div className="col-12 container">
														<ul className="list-bullets row">
															{model.jd?.methodologies.map((singleMethodology, index) => {
																const methodologyValues = singleMethodology.values || [];
																let singleMethodologyValues = ` (${(methodologyValues || []).join(", ")})`;
																if (methodologyValues.length === 0) {
																	singleMethodologyValues = "";
																}

																return (
																	<li className="col-3" key={`singleMethodology_${index}`}>
																		<small dangerouslySetInnerHTML={{ __html: highlightText(singleMethodology.description || "", this.state.searchQueryTags).trim() }}></small>
																	</li>)
																})}
														</ul>
													</div>
												</span>
											}
											
										</div>
										<div className="row d-block d-lg-none">
											<div className="col-12">
												<div className="widget bg-white p-lr20 p-t20  widget_getintuch radius-sm">
													<div className="d-flex justify-content-center">
														<a href={model.jd?.originalUri} className="site-button mb-2 width100Percent" target='_blank' rel="noreferrer" onClick={() => this.setState({ showSaveThisApplicationInHistoryModal: true })}>Aplikuję!</a> {/* //todo: fudged! in other places as well! */}
													</div>
													<SessionAuth doRedirection={false} requireAuth={true} key={"LoggedInUser"}>
														<div className="d-flex justify-content-center">
															<a href="#" className="site-button mb-2 width100Percent" onClick={() => this.setState({ showSaveThisApplicationInHistoryModal: true })}>Już zaaplikowane!</a> {/* //todo: fudged! in other places as well! */}
														</div>
													</SessionAuth>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>						
					</div>
				</div>
				
				<SaveThisApplicationInHistoryModalComponent show={this.state.showSaveThisApplicationInHistoryModal} jobOfferId={this.state.jd?.id || 0} onClose={() => this.setState({ showSaveThisApplicationInHistoryModal: false}) } />
				
				<Footer />
			</div>		
		)
		
	}
}
export default withRouter(Jobdetail);