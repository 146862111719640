import { GeneralApplicationDataConfigurator } from './GeneralApplicationDataConfigurator';

let Types = {
    AuthorisationServerDataSettings: Symbol("AuthorisationServerDataSettings"),
    JobsApiSettings: Symbol("JobsApiSettings"),
    GeneralApplicationSettings: Symbol("GeneralApplicationSettings"),
    AuthorisationServerConfigurator: Symbol("AuthorisationServerConfigurator"),
    AppliedJobsRetrieverSettings: Symbol("AppliedJobsRetrieverSettings"),
    AppliedJobsRemoverSettings: Symbol("AppliedJobsRemoverSettings"),
    AppliedResumeFilesRetrieverSettings: Symbol("AppliedResumeFilesRetrieverSettings"),
    ScrappingGuardSettings: Symbol("ScrappingGuardSettings"),
    TrackingDataSettings: Symbol("TrackingDataSettings"),
    TrackingDataConfigurator: Symbol("TrackingDataConfigurator"),
    UserSettingsSettings: Symbol("UserSettingsSettings"),
    GeneralApplicationDataConfigurator: Symbol("GeneralApplicationDataConfigurator"),
    SystemSettingsSettings: Symbol("SystemSettingsSettings"),
}

export { Types };

export * from './IKeepJobsApiData';
export * from './IKeepAuthorisationServerData';
export * from './IKeepGeneralApplicationData';
export * from './IKeepAppliedJobsApiData';
export * from './IKeepAppliedResumeFilesApiData';
export * from './IKeepScrappingGuardData';
export * from './IKeepTrackingData';
export * from './IKeepUserSettingsApiData';
export * from './IKeepSystemSettingsData';