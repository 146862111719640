import { inject, injectable } from "inversify";
import * as config from ".";

@injectable()
export class GeneralApplicationDataConfigurator implements config.IConfigureGeneralApplicationData {
    @inject(config.Types.GeneralApplicationSettings)
    private readonly _generalApplicationDataSettings!: config.IKeepGeneralApplicationData;

    public Configure(): void {
        if (this._generalApplicationDataSettings.applicationBuildId !== undefined && this._generalApplicationDataSettings.applicationBuildId !== "") {
            console.log(`Application build id: ${this._generalApplicationDataSettings.applicationBuildId}`);
        }
    }
}