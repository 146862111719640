import React,{Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import './PrivacyPolicy.css';

var bnr = require('./../../images/main-slider/slide1.jpg');

class PrivacyPolicy_01_10_2024 extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />
                <div className="page-content">				
                    <div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry align-m text-white">
                                <div className="col-lg-12 col-md-12 col-sm-12 error-page text-center bg-black">
                                    <hr />
                                    <h2 className="text-secondry text-uppercase">Polityka prywatności</h2>
                                    <h2 className="text-secondry text-uppercase">all-it.jobs</h2>
                                    <span className='text-secondry text-lowercase'>(Wersja z dnia 01.10.2024 r.)</span>
                                    <hr />
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 1 Postanowienia ogólne</span>
                                        </p>
                                        <p>
                                            <div>1.1. Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników serwisu internetowego dostępnego pod adresem all-it.jobs (dalej: „Serwis”), prowadzonego przez QuadSoft Rafał Kozłowski, z siedzibą we Wrocławiu, ul. Starodębowa 24 (dalej: „Administrator”).</div>
                                            <div>1.2. Administrator dokłada wszelkich starań, aby przetwarzanie danych osobowych odbywało się zgodnie z obowiązującymi przepisami prawa, w tym w szczególności z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych (RODO).</div>
                                            <div>1.3. Kontakt z Administratorem możliwy jest za pomocą e-maila: <a href='mailto:biuro@all-it.jobs' className='text-white'>biuro@all-it.jobs</a> lub pisemnie na adres siedziby.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 2 Zakres i cele przetwarzania danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>2.1. Administrator przetwarza dane osobowe Użytkowników w następujących celach:</div>
                                            <div>a) <span className='text-weight-bold'>Rejestracja i prowadzenie konta Użytkownika</span> – dane przetwarzane w celu utworzenia i utrzymania konta Użytkownika w Serwisie, umożliwienia logowania oraz korzystania z funkcji związanych z aplikowaniem na oferty pracy.</div>
                                            <div>b) <span className='text-weight-bold'>Realizacja usług świadczonych drogą elektroniczną</span> – dane niezbędne do umożliwienia korzystania z funkcjonalności Serwisu, takich jak zapis historii aplikacji, załączanie CV do ofert pracy.</div>
                                            <div>c) <span className='text-weight-bold'>Dopasowanie ofert pracy</span> – przetwarzanie danych, w tym CV, w celu oferowania ofert pracy zgodnych z kwalifikacjami Użytkownika, w tym z użyciem algorytmów analizy danych i sztucznej inteligencji (AI).</div>
                                            <div>d) <span className='text-weight-bold'>Marketing bezpośredni</span> – wysyłanie informacji o nowych funkcjonalnościach Serwisu, ofertach pracy oraz innych treściach marketingowych, na podstawie zgody Użytkownika.</div>
                                            <div>e) <span className='text-weight-bold'>Obsługa zgłoszeń</span> – kontaktowanie się z Użytkownikami w celach związanych z obsługą zgłoszeń technicznych, pytań lub reklamacji.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 3 Zakres i cele przetwarzania danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>3.1. Administrator przetwarza dane osobowe Użytkowników w następujących celach:</div>
                                            <div>a) <span className='text-weight-bold'>Rejestracja i prowadzenie konta Użytkownika</span> – dane przetwarzane w celu utworzenia i utrzymania konta Użytkownika w Serwisie, umożliwienia logowania oraz korzystania z funkcji związanych z aplikowaniem na oferty pracy.</div>
                                            <div>b) <span className='text-weight-bold'>Realizacja usług świadczonych drogą elektroniczną</span> – dane niezbędne do umożliwienia korzystania z funkcjonalności Serwisu, takich jak zapis historii aplikacji, załączanie CV do ofert pracy.</div>
                                            <div>c) <span className='text-weight-bold'>Dopasowanie ofert pracy</span> – przetwarzanie danych, w tym CV, w celu oferowania ofert pracy zgodnych z kwalifikacjami Użytkownika, w tym z użyciem algorytmów analizy danych i sztucznej inteligencji (AI).</div>
                                            <div>d) <span className='text-weight-bold'>Marketing bezpośredni</span> – wysyłanie informacji o nowych funkcjonalnościach Serwisu, ofertach pracy oraz innych treściach marketingowych, na podstawie zgody Użytkownika.</div>
                                            <div>e) <span className='text-weight-bold'>Obsługa zgłoszeń</span> – kontaktowanie się z Użytkownikami w celach związanych z obsługą zgłoszeń technicznych, pytań lub reklamacji.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 4 Rodzaje przetwarzanych danych</span>
                                        </p>
                                        <p>
                                            <div>4.1. Administrator przetwarza następujące dane osobowe Użytkowników:</div>
                                            <div>a) dane podawane podczas rejestracji, takie jak adres e-mail, hasło,</div>
                                            <div>b) dane związane z aplikacjami na oferty pracy, w tym CV,</div>
                                            <div>c) dane związane z aktywnością w Serwisie, takie jak historia wyszukiwanych ofert pracy, zapisanych ofert oraz aplikacji,</div>
                                            <div>d) dane niezbędne do wysyłki newslettera, w przypadku wyrażenia zgody na otrzymywanie informacji handlowych.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 5 Podstawa prawna przetwarzania danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>5.1. Administrator przetwarza dane osobowe na podstawie:</div>
                                            <div>a) <span className='text-weight-bold'>Zgody Użytkownika</span> (art. 6 ust. 1 lit. a RODO) – w odniesieniu do przesyłania informacji handlowych, dopasowywania ofert pracy oraz przetwarzania CV,</div>
                                            <div>b) <span className='text-weight-bold'>Realizacji umowy</span> (art. 6 ust. 1 lit. b RODO) – w zakresie przetwarzania danych niezbędnych do świadczenia usług oferowanych przez Serwis,</div>
                                            <div>c) <span className='text-weight-bold'>Obowiązków prawnych</span> (art. 6 ust. 1 lit. c RODO) – wynikających z przepisów dotyczących ochrony danych osobowych i rachunkowości,</div>
                                            <div>d) <span className='text-weight-bold'>Prawnie uzasadnionych interesów Administratora</span> (art. 6 ust. 1 lit. f RODO) – w celach związanych z ewentualnym dochodzeniem roszczeń lub obroną przed roszczeniami.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 6 Przekazywanie danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>6.1. Dane osobowe Użytkowników mogą być przekazywane następującym podmiotom:</div>
                                            <div>a) <span className='text-weight-bold'>Podmioty obsługujące Serwis</span> – zewnętrzne firmy świadczące usługi hostingowe, informatyczne, analityczne, obsługę narzędzi marketingowych, które działają na zlecenie Administratora i przetwarzają dane zgodnie z obowiązującymi przepisami prawa oraz na podstawie odpowiednich umów powierzenia danych.</div>
                                            <div>b) <span className='text-weight-bold'>Organy publiczne</span> – w przypadkach przewidzianych przepisami prawa, np. na żądanie organów ścigania, sądów lub innych instytucji państwowych.</div>
                                            <div>6.2. Dane osobowe nie są przekazywane poza Europejski Obszar Gospodarczy (EOG), chyba że zapewniono odpowiednie zabezpieczenia zgodnie z RODO.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 7 Okres przechowywania danych</span>
                                        </p>
                                        <p>
                                            <div>7.1. Dane osobowe Użytkowników przechowywane są przez okres niezbędny do realizacji celów, dla których zostały zebrane:</div>
                                            <div>a) dane związane z prowadzeniem konta Użytkownika – do momentu usunięcia konta przez Użytkownika,</div>
                                            <div>b) dane związane z aplikacjami na oferty pracy – przez okres zgodny z przepisami dotyczącymi przechowywania dokumentów rekrutacyjnych,</div>
                                            <div>c) dane przetwarzane na podstawie zgody Użytkownika – do momentu wycofania zgody,</div>
                                            <div>d) dane przetwarzane na potrzeby dochodzenia roszczeń – przez okres przedawnienia roszczeń wynikający z przepisów prawa.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 8 Prawa Użytkowników</span>
                                        </p>
                                        <p>
                                            <div>8.1. Użytkownikowi przysługują następujące prawa dotyczące jego danych osobowych:</div>
                                            <div>a) <span className='text-weight-bold'>Prawo dostępu do danych</span> – Użytkownik ma prawo do uzyskania informacji na temat przetwarzania swoich danych oraz do otrzymania ich kopii.</div>
                                            <div>b) <span className='text-weight-bold'>Prawo do sprostowania danych</span> – Użytkownik ma prawo do żądania poprawienia nieprawidłowych lub uzupełnienia niekompletnych danych.</div>
                                            <div>c) <span className='text-weight-bold'>Prawo do usunięcia danych</span> – Użytkownik ma prawo do żądania usunięcia swoich danych, jeśli nie są już one niezbędne do celów, dla których zostały zebrane, lub jeśli wycofał zgodę na ich przetwarzanie. Żądanie usunięcia danych można przesłać na adres e-mail: biuro@all-it.jobs.</div>
                                            <div>d) <span className='text-weight-bold'>Prawo do ograniczenia przetwarzania</span> – Użytkownik może żądać ograniczenia przetwarzania danych w przypadkach określonych w art. 18 RODO.</div>
                                            <div>e) <span className='text-weight-bold'>Prawo do przenoszenia danych</span> – Użytkownik ma prawo do otrzymania swoich danych osobowych w ustrukturyzowanym formacie oraz do przeniesienia ich do innego administratora.</div>
                                            <div>f) <span className='text-weight-bold'>Prawo do sprzeciwu</span> – Użytkownik może zgłosić sprzeciw wobec przetwarzania danych na podstawie prawnie uzasadnionych interesów Administratora, w tym w celach marketingowych.</div>
                                            <div>g) <span className='text-weight-bold'>Prawo do wycofania zgody</span> – Użytkownik może w dowolnym momencie wycofać swoją zgodę na przetwarzanie danych, bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</div>
                                            <div>8.2. Użytkownik może skorzystać z powyższych praw, kontaktując się z Administratorem za pomocą adresu e-mail: <a href='mailto:biuro@all-it.jobs' className='text-white'>biuro@all-it.jobs</a> lub pisemnie na adres siedziby.</div>
                                            <div>8.3. Użytkownik ma prawo wniesienia skargi do organu nadzorczego, jeżeli uzna, że przetwarzanie jego danych osobowych narusza przepisy RODO.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 9 Zabezpieczenia danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>9.1. Administrator stosuje odpowiednie środki techniczne i organizacyjne, aby chronić dane osobowe Użytkowników przed nieuprawnionym dostępem, utratą, zmianą lub zniszczeniem, zgodnie z obowiązującymi przepisami prawa.</div>
                                            <div>9.2. Dostęp do danych osobowych mają jedynie osoby upoważnione przez Administratora, zobowiązane do zachowania poufności.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 10 Zmiany Polityki Prywatności</span>
                                        </p>
                                        <p>
                                            <div>10.1. Administrator zastrzega sobie prawo do dokonywania zmian w Polityce Prywatności w przypadku zmiany przepisów prawa lub wprowadzenia nowych funkcjonalności Serwisu, które wpływają na zakres przetwarzania danych osobowych.</div>
                                            <div>10.2. O wszelkich zmianach Użytkownicy zostaną poinformowani poprzez odpowiednie komunikaty na stronie internetowej Serwisu lub poprzez komunikację email.</div>
                                        </p>
                                    </p>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>	
                
                <Footer />
            </div>
        )
    }
}

export default PrivacyPolicy_01_10_2024;