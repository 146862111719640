import { injectable } from "inversify";
import { IKeepJobsApiData } from "./IKeepJobsApiData";
import { IKeepAuthorisationServerData } from "./IKeepAuthorisationServerData";
import { IKeepGeneralApplicationData } from "./IKeepGeneralApplicationData";
import { IKeepAppliedJobsApiData } from "./IKeepAppliedJobsApiData";
import { IKeepAppliedResumeFilesApiData } from "./IKeepAppliedResumeFilesApiData";
import { IKeepScrappingGuardData } from "./IKeepScrappingGuardData";
import { IKeepTrackingData } from "./IKeepTrackingData";
import { IKeepUserSettingsApiData } from "./IKeepUserSettingsApiData";
import { IKeepSystemSettingsData } from "./IKeepSystemSettingsData";

@injectable()
export class ApplicationConfiguration 
    implements 
        IKeepJobsApiData, 
        IKeepAuthorisationServerData, 
        IKeepGeneralApplicationData, 
        IKeepAppliedJobsApiData, 
        IKeepAppliedResumeFilesApiData,
        IKeepScrappingGuardData,
        IKeepTrackingData,
        IKeepUserSettingsApiData,
        IKeepSystemSettingsData {

    public get jobsOfferApiUrl(): string { 
        const url = process.env.REACT_APP_SEARCH_JOB_OFFERS_API;        
        return url!;
    }

    public get applicationName(): string {
        const applicationName = process.env.REACT_APP_AUTHENTICATION_SERVER_APPLICATION_NAME; 
        return applicationName!;
    }

    public get applicationUrl(): string {
        const applicationUrl = process.env.REACT_APP_WEBSITE_URL; 
        return applicationUrl!;
    }

    public get authorisationServerApiUrl(): string {
        const authorizationServerUrl = process.env.REACT_APP_AUTHENTICATION_SERVER_URL; 
        return authorizationServerUrl!;
    }

    public get appliedJobsApiUrl(): string {
        const appliedJobsApiUrl = process.env.REACT_APP_APPLIED_JOBS_API_URL; 
        return appliedJobsApiUrl!;
    }

    public get appliedResumeFilesApiUrl(): string {
        const appliedResumeFilesApiUrl = process.env.REACT_APP_APPLIED_RESUME_FILES_API_URL; 
        return appliedResumeFilesApiUrl!;
    }

    public get scrappingGuardApiUrl(): string {
        const scrappingGuardApiUrl = process.env.REACT_APP_SCRAPPING_GUARD_API_URL; 
        return scrappingGuardApiUrl!;
    }

    public get googleMeasurementTagId(): string {
        const googleMeasurementTagId = process.env.REACT_APP_GOOGLE_MEASUREMENT_TAG_ID;
        return googleMeasurementTagId!;
    }

    public get userSettingsApiUrl(): string {
        const userSettingsApiUrl = process.env.REACT_APP_USER_SETTINGS_API_URL; 
        return userSettingsApiUrl!;
    }

    public get applicationBuildId(): string {
        const applicationBuildId = process.env.REACT_APP_BUILD_ID;
        return applicationBuildId!;
    }

    public get systemSettingsApiUrl(): string {
        const systemSettingsApiUrl = process.env.REACT_APP_SYSTEM_SETTINGS_API_URL;
        return systemSettingsApiUrl!;
    }

    public get fetchLoadingIndicatorUrls(): string[] {
        return [
            this.jobsOfferApiUrl,
            this.authorisationServerApiUrl,
            this.appliedJobsApiUrl,
            this.appliedResumeFilesApiUrl,
            this.scrappingGuardApiUrl,
            this.userSettingsApiUrl,
            this.systemSettingsApiUrl
        ];
    }
}
