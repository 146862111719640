import React from 'react';

import CookieConsent,{ getCookieConsentValue } from "react-cookie-consent";
import Toggle from 'react-toggle';

import * as config from '../../config';
import { resolve } from 'inversify-react';

const borderNoneStyle = {border: 'none'};

const cookieConsentsName = 'cookieConsents';

export class CookieConsentsOverlay extends React.Component {

    @resolve(config.Types.TrackingDataConfigurator)
    private readonly _trackingDataConfigurator!: config.IConfigureTrackingData;

    state: { showCookieConsentsSettingsForm: boolean; acceptButtonText: string; finalCookieValue: {functional: boolean; analitics: boolean; advertisement: boolean}; };

    constructor(props: any) {
      super(props);
      this.state = {
        showCookieConsentsSettingsForm: false,
        acceptButtonText: 'Akceptuję wszystkie cookies',
        finalCookieValue: {functional: true, analitics: true, advertisement: true}
      };

      this.handleCookieConsentsSettingsForm = this.handleCookieConsentsSettingsForm.bind(this);
      this.handleCookieConsents = this.handleCookieConsents.bind(this);
    }

    handleCookieConsentsSettingsForm = () => {
      const showCookieConsentsSettingsForm = !this.state.showCookieConsentsSettingsForm;
      const acceptButtonText = showCookieConsentsSettingsForm ? 'Akceptuję wszystkie cookies' : 'Zapisz ustawienia';

      this.setState({ showCookieConsentsSettingsForm: showCookieConsentsSettingsForm, acceptButtonText: acceptButtonText });
    }
  
    handleCookieConsents = (consentValue: boolean, consentName: string) => {
      this.setState({ finalCookieValue: { ...this.state.finalCookieValue, [consentName]: consentValue } });
    }

    render() {
      return (
        <CookieConsent 
          location='top' 
          overlay={true} 
          style={{background: 'white', color: '', padding: '20px'}} 
          overlayStyle={{zIndex: 2147483647, background: "rgba(0,0,0,0.7)"}} 
          buttonText={this.state.acceptButtonText}
          buttonClasses='btn site-button' 
          buttonStyle={{background: "", color: "", padding: '10px', fontWeight: "bold"}}
          cookieValue={this.state.finalCookieValue}
          cookieName={cookieConsentsName}
          onAccept={() => { this._trackingDataConfigurator.Configure(); }}
          >
        <div className="container">
          <div className='row text-justify'>
            <span>
            <a href='https://all-it.jobs'>all-it.jobs</a> używa cookies. Część z nich jest niezbędna do tego, żeby strona działała prawidłowo. 
            Używamy także cookies do zaawansowanej analityki i celów marketingowych, użycie tych cookies możesz <a href='#' onClick={ this.handleCookieConsentsSettingsForm }>dostosować</a>. Dowiesz się o nich więcej w naszej <a href="/privacyPolicy" target='_blank' rel="noreferrer noopener">polityce prywatności</a>.
            </span>
          </div>
          {this.state.showCookieConsentsSettingsForm && (<>
              <hr />
                <div className="row">
                  <div className='col-xs-12 col-md-4 col-lg-4 input-group form-control align-items-center' style={borderNoneStyle}>
                      <Toggle id='showFunctionalCookieConsentSettings' checked={true} disabled={true} ></Toggle>&nbsp;Cookies funkcjonalne
                  </div>
                  <div className='col-xs-12 col-md-4 col-lg-4 input-group form-control align-items-center' style={borderNoneStyle}>
                      <Toggle id='showAnaliticsCookieConsentSettings' defaultChecked={true} onChange={(e) => { this.handleCookieConsents(e.target.checked, 'analitics') } }></Toggle>&nbsp;Zaawansowana analityka
                  </div>
                  <div className='col-xs-12 col-md-4 col-lg-4 input-group form-control align-items-center' style={borderNoneStyle}>
                      <Toggle id='showAdvertisementCookieConsentSettings' defaultChecked={true} onChange={(e) => { this.handleCookieConsents(e.target.checked, 'advertisement') } }></Toggle>&nbsp;Reklama personalizowana
                  </div>
                </div>
              </>
          )}

        </div>
        </CookieConsent>
      );
    }
}

export function getCookieConsents(): {functional: boolean, analitics: boolean, advertisement: boolean} {
    const cookieConsents = getCookieConsentValue(cookieConsentsName);

    if (cookieConsents) {
        return { ...JSON.parse(cookieConsents), functional: true };
    }

    return {
        functional: true,
        analitics: false,
        advertisement: false
    };
}