import { inject, injectable } from "inversify";
import * as config from "../../../config";

import { OpenAPI, SettingsService as SystemSettingsServiceBase } from "@all-it/api.settings.client";

import Session from 'supertokens-auth-react/recipe/session';
import { IGetSystemSettingsService, ISystemSetting, IUpdateSystemSettingsService } from "./abstraction";
import { Guid } from "typescript-guid";


@injectable()
export class SystemSettingsService implements IGetSystemSettingsService, IUpdateSystemSettingsService {
    private readonly _allowedSystemSettingNames: string[] = [ 'MaintenanceMode', 'LocationNormalisationRules' ];

    @inject(config.Types.SystemSettingsSettings)
    private readonly _apiSettings!: config.IKeepSystemSettingsData;

    async getSystemSettings(): Promise<ISystemSetting[]> {
        const payload = await Session.getAccessToken();
        const jwt = payload;

        OpenAPI.BASE = this._apiSettings.systemSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        const settingsFetchedPromises = this._allowedSystemSettingNames.map((settingName) => {
            return SystemSettingsServiceBase.settingsGet1(settingName)
        });

        const settings = await Promise.all(settingsFetchedPromises);

        return settings.map(setting => {
            return {
                name: setting.name!,
                value: this.safeParseJson(setting.value!) || setting.value,
                version: Guid.parse(setting.version!)
            }
        });
    }

    async getSetting(name: string): Promise<ISystemSetting> {
        const payload = await Session.getAccessToken();
        const jwt = payload;

        OpenAPI.BASE = this._apiSettings.systemSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        const setting = await SystemSettingsServiceBase.settingsGet1(name);

        return {
            name: setting.name!,
            value: this.safeParseJson(setting.value!),
            version: Guid.parse(setting.version!)
        };
    }

    async updateSetting(setting: ISystemSetting): Promise<Guid> {
        const payload = await Session.getAccessToken();
        const jwt = payload;

        OpenAPI.BASE = this._apiSettings.systemSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        let settingValueAsString = setting.value;
        if (typeof setting.value !== 'string') {
            settingValueAsString = JSON.stringify(setting.value, null, 2);
        }

        const retVal = await SystemSettingsServiceBase.settingsPost({ name: setting.name, value: settingValueAsString, version: setting.version.toString() });        
        return Guid.parse(retVal.version!);
    }

    private safeParseJson(json: string): any {
        try {
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }
}