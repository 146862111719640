import { inject, injectable } from "inversify";
import * as config from ".";
import ThirdPartyPasswordless from "supertokens-auth-react/recipe/thirdparty";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import SuperTokens from "supertokens-auth-react";

@injectable()
export class AuthorisationServerConfigurator implements config.IConfigureAuthorisationServer {
    @inject(config.Types.AuthorisationServerDataSettings)
    private readonly _authorisationServerData!: config.IKeepAuthorisationServerData;

    @inject(config.Types.GeneralApplicationSettings)
    private readonly _generalApplicationSettings!: config.IKeepGeneralApplicationData;

    public Configure(): void {
        const SuperTokensConfig = {
            termsOfServiceLink: "/termsOfService", 
            privacyPolicyLink: "/privacyPolicy",
            disableAuthRoute: true,
            appInfo: {
                appName: this._generalApplicationSettings.applicationName,
                apiDomain: this._authorisationServerData.authorisationServerApiUrl,
                websiteDomain: this._generalApplicationSettings.applicationUrl,
                apiBasePath: '/auth',
                websiteBasePath: '/auth'
            },
            recipeList: [
                ThirdPartyPasswordless.init({
                    signInAndUpFeature: {
                        providers: [
                            ThirdPartyPasswordless.Google.init(),
                            ThirdPartyPasswordless.Facebook.init(),
                        ],
                    }
                }),
                Passwordless.init({
                    contactMethod: "EMAIL"
                }),
                Session.init({
                    tokenTransferMethod: "header"
                }),
            ],
            languageTranslations: {
                translations: {
                    pl: {
                        AUTH_PAGE_HEADER_TITLE_SIGN_IN_AND_UP: "Zaloguj / zarejestruj",
                        AUTH_PAGE_HEADER_TITLE_SIGN_IN: "Zaloguj się",
                        AUTH_PAGE_HEADER_TITLE_SIGN_UP: "Zarejestruj się",

                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_START: "Nie masz jeszcze konta?",
                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_SIGN_UP_LINK: "Zarejestruj się",
                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_IN_END: "",

                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_START: "Masz już konto?",
                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_SIGN_IN_LINK: "Zaloguj się",
                        AUTH_PAGE_HEADER_SUBTITLE_SIGN_UP_END: "",

                        PWLESS_CLOSE_TAB_TITLE: "Sukces!",
                        PWLESS_CLOSE_TAB_SUBTITLE_LINE1: "Zalogowano pomyślnie.",
                        PWLESS_CLOSE_TAB_SUBTITLE_LINE2: "",

                        PWLESS_SIGN_IN_UP_CONTINUE_BUTTON: "Kontynuuj",
                        PWLESS_SIGN_IN_UP_HEADER_TITLE: "Zaloguj się",
                        PWLESS_SIGN_IN_UP_CHANGE_CONTACT_INFO_EMAIL: "Zmień adres e-mail",
                        PWLESS_SIGN_IN_UP_EMAIL_LABEL: "Adres e-mail",
                        
                        THIRD_PARTY_PASSWORDLESS_SIGN_IN_AND_UP_HEADER_TITLE: "Zaloguj się",
                        THIRD_PARTY_PROVIDER_DEFAULT_BTN_START: "Zaloguj/zarejestruj przez ",

                        GENERAL_ERROR_EMAIL_UNDEFINED: "Nie podano adresu e-mail.",
                        GENERAL_ERROR_EMAIL_NON_STRING: "Adres e-mail musi być ciągiem znaków.",
                        GENERAL_ERROR_EMAIL_INVALID: "Adres e-mail jest nieprawidłowy.",

                        GENERAL_ERROR_OTP_UNDEFINED: "Nie podano kodu jednorazowego.",
                        GENERAL_ERROR_OTP_INVALID: "Kod jednorazowy jest nieprawidłowy.",
                        GENERAL_ERROR_OTP_EXPIRED: "Kod jednorazowy wygasł.",
                        GENERAL_ERROR_OTP_NON_STRING: "Kod jednorazowy musi być ciągiem znaków.",
                        GENERAL_ERROR_OTP_EMPTY: "Kod jednorazowy nie może być pusty.",

                        ERROR_SIGN_IN_UP_RESEND_RESTART_FLOW: "Logowanie zajęło zbyt dużo czasu. Spróbuj ponownie.",
                        ERROR_SIGN_IN_UP_CODE_CONSUME_RESTART_FLOW: "Nieudane logowanie. Spróbuj ponownie.",

                        PWLESS_RESEND_SUCCESS_EMAIL: "Wysłano ponownie kod jednorazowy.",
                        PWLESS_RESEND_BTN_DISABLED_START: "Wyślij ponownie za ",
                        PWLESS_RESEND_BTN_DISABLED_END: " sekund",
                        PWLESS_RESEND_BTN_EMAIL: "Wyślij ponownie kod jednorazowy",

                        PWLESS_USER_INPUT_CODE_HEADER_TITLE: "Wprowadź kod jednorazowy",
                        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE: "Wprowadź kod jednorazowy wysłany na",
                        PWLESS_USER_INPUT_CODE_HEADER_SUBTITLE_LINK: "Kod jednorazowy i magiczny link zostały wysłane na",
                        PWLESS_USER_INPUT_CODE_INPUT_LABEL: "Kod jednorazowy",

                        AUTH_PAGE_FOOTER_START: "Korzystając z tej strony, zgadzasz się na nasze ",
                        AUTH_PAGE_FOOTER_TOS: "warunki korzystania z usługi",
                        AUTH_PAGE_FOOTER_AND: " i ",
                        AUTH_PAGE_FOOTER_PP: "politykę prywatności",
                        AUTH_PAGE_FOOTER_END: "",

                        DIVIDER_OR: "lub",

                        BRANDING_POWERED_BY_START: "Powered by ",
                        BRANDING_POWERED_BY_END: "",
                        SOMETHING_WENT_WRONG_ERROR: "Coś poszło nie tak. Spróbuj ponownie później.",
                        SOMETHING_WENT_WRONG_ERROR_RELOAD: "coś poszło nie tak. Odśwież stronę i spróbuj ponownie.",

                        "Failed to generate a one time code. Please try again": "Nie udało się wygenerować kodu jednorazowego. Spróbuj ponownie.",
                    }
                }
            }
        };

        SuperTokens.init({ ...SuperTokensConfig});
        SuperTokens.changeLanguage("pl"); //todo: fudged!
    }
}