import React from "react";
import { Component } from "react";
import CreatableSelect from 'react-select/creatable';

const creatableSelectAsRegularInputStyle = {
	control: (provided: any) => ({
	  ...provided,
   	  border: '1px solid #ccc',
	  boxShadow: 'none',
	  '&:hover': {
		border: '1px solid #aaa',
	  },
	  minHeight: '38px', // Adjust height to match input field
	  height: 'auto',
	}),
	multiValue: (provided: any) => ({
	  ...provided,
	  backgroundColor: '#e2e2e2',
	}),
	multiValueLabel: (provided: any) => ({
	  ...provided,
	  color: '#333',
	}),
	multiValueRemove: (provided: any) => ({
	  ...provided,
	  cursor: 'pointer',
	  '&:hover': {
		backgroundColor: '#2e55fa',
		color: 'white',
	  },
	}),
	input: (provided: any) => ({
	  ...provided,
	  margin: 0,
	  padding: 0
	}),
	indicatorsContainer: () => ({
	  display: 'none', // Hide the dropdown arrow
	}),
	dropdownIndicator: () => ({
	  display: 'none', // Also hides the dropdown arrow
	}),
	indicatorSeparator: () => ({
	  display: 'none', // Remove the vertical separator next to the dropdown
	}),
	menu: () => ({
	  display: 'none', // Disable the dropdown menu
	}),
  };


export default class SearchTextTags extends Component<ISearchTextTagsProps, ISearchTextTagsState> {
    constructor(props: ISearchTextTagsProps) {
      super(props);
  
      this.state = { tags: props.searchTextTags || [] };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value: string[]) {
        this.setState({ tags: value });
		if (this.props.onSearchTextTagsChange !== undefined) {
        	this.props.onSearchTextTagsChange(value);
		}
    }

	componentDidUpdate(prevProps: Readonly<ISearchTextTagsProps>, prevState: Readonly<ISearchTextTagsState>, snapshot?: any): void {
		if (prevProps.searchTextTags !== this.props.searchTextTags) {
			this.setState({ tags: this.props.searchTextTags || [] });
		}
	}
	
    render() {
		return (
            <CreatableSelect
				isMulti
				noOptionsMessage={() => null} 
				className="form-control creatableSelect" 
				styles={creatableSelectAsRegularInputStyle} 
				autoFocus={true}
				options={this.state.tags.map((tag: string) => ({ label: tag, value: tag }))} 
				value={this.state.tags.map((tag: string) => ({ label: tag, value: tag }))}
				onChange={(e) => {
					this.setState({ tags: e.map((val: any) => val.value) });
					if (this.props.onSearchTextTagsChange !== undefined) {
						this.props.onSearchTextTagsChange(e.map((val: any) => val.value));
					}
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter" && this.props.onTypingFinished !== undefined) {
						const target = e.target as HTMLInputElement;
						if (target.value === "") {
							this.props.onTypingFinished(this.state.tags);
							e.preventDefault();
						}
					}
				}}
				onBlur={(e) => {
					if (e.target.value !== "") {
						this.setState({ tags: [...this.state.tags, e.target.value] });
						if (this.props.onSearchTextTagsChange !== undefined) {
							this.props.onSearchTextTagsChange([...this.state.tags, e.target.value]);
						}
					}
				}}
				placeholder="Wpisz tutaj czego szukasz..."
            />
        );
    }
}

interface ISearchTextTagsProps {
    onSearchTextTagsChange?: (searchTextTags: string[]) => void;
	onTypingFinished?: (searchTextTags: string[]) => void;
	searchTextTags?: string[];
}

interface ISearchTextTagsState {
    tags: string[];
}


