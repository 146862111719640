import { inject, injectable } from "inversify";
import * as config from "../../config";
import { IGetLocationsForJobSearch } from "./abstraction";
import { OpenAPI, JobOffersLocationsService, SearchJobOffersLocationsResult } from "@all-it/api.joboffers.client";
import * as Cache from "../Cache";
import Session from 'supertokens-auth-react/recipe/session';

@injectable()
export class LocationsForJobSearchService implements IGetLocationsForJobSearch {
    @inject(config.Types.JobsApiSettings)
    private readonly _searchApiSettings!: config.IKeepJobsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cache!: Cache.ICacheData;
    
    async get(): Promise<string[]> {
        const cacheKey = "JobOffersLocationsDictionary";
        const locationsCached = this._cache.get<SearchJobOffersLocationsResult[]>(cacheKey);
        if (locationsCached != null) 
        {
            return locationsCached.map(val => val.location!);
        }

        OpenAPI.BASE = this._searchApiSettings.jobsOfferApiUrl;

        if (await Session.doesSessionExist()) {
            const jwt = await Session.getAccessToken();

            OpenAPI.TOKEN = jwt;
        }

        const locations = await JobOffersLocationsService.jobOffersLocationsGetAll();
        const locationsSorted = locations.sort((a: SearchJobOffersLocationsResult, b: SearchJobOffersLocationsResult) => {
            return a.occurrences! > b.occurrences! ? -1 : 1;
        });

        this._cache.put(cacheKey, locationsSorted, 1000 * 60 * 60); //todo: create type like timespan with factories c# style like 'FromSeconds' https://stackoverflow.com/questions/49216952/how-to-work-with-timespan-in-typescript

        return locationsSorted.map(val => val.location + '');         
    }
}