import { Guid } from "typescript-guid";

export interface ISystemSetting {
    name: string;
    value: any;
    version: Guid;
}

export interface IGetSystemSettingsService {
    getSetting(name: string): Promise<ISystemSetting>;
    getSystemSettings(): Promise<ISystemSetting[]>;
} 

export interface IUpdateSystemSettingsService {
    updateSetting(setting: ISystemSetting): Promise<Guid>;
}

let Types = {
    GetSystemSettingsService: Symbol("GetSystemSettingsService"),
    UpdateSystemSettingsService: Symbol("UpdateSystemSettingsService"),
}

export { Types };