import { inject, injectable } from "inversify";
import { ISaveUserSettings, IUserSettings } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { SettingsService, OpenAPI } from "@all-it/api.users.client";

@injectable()
export class UserSettingsSaver implements ISaveUserSettings {
    @inject(config.Types.UserSettingsSettings)
    private readonly _userSettingsSettings!: config.IKeepUserSettingsApiData;

    async save(settings: IUserSettings): Promise<void> {
        const jwt = await Session.getAccessToken();    

        OpenAPI.BASE = this._userSettingsSettings.userSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        await SettingsService
            .settingsPost({
                notificationSettings: {
                    mailMeAboutNews: settings.notifications.mailMeAboutNews,
                }
            });
    }
}