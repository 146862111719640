import { inject, injectable } from "inversify";
import { IRetrieveUserSettings, IUserSettings } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { SettingsService, OpenAPI } from "@all-it/api.users.client";

@injectable()
export class UserSettingsRetriever implements IRetrieveUserSettings {
    @inject(config.Types.UserSettingsSettings)
    private readonly _userSettingsApiSettings!: config.IKeepUserSettingsApiData;

    async retrieve(): Promise<IUserSettings> {
        const jwt = await Session.getAccessToken();

        OpenAPI.BASE = this._userSettingsApiSettings.userSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        const settings = await SettingsService.settingsGet();

        if (settings == null) {
            return {
                notifications: {
                    mailMeAboutNews: true
                }
            };
        }

        return {
            notifications: {
                mailMeAboutNews: settings!.notifications!.mailMeAboutNews!
            }
        };
    }
}