import React from 'react';
import Markup from './markup/Markup';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import './plugins/slick/slick.min.css';
import './plugins/slick/slick-theme.min.css';

import "reflect-metadata";

import "moment/locale/pl"
import "moment/locale/en-gb"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FetchLoadingIndicator } from './markup/Element/FetchLoadingIndicator';
import { FetchUnathorizedInterceptor } from './markup/Element/FetchUnathorizedInterceptor';
import { FetchMaintenanceModeRedirector } from './markup/Element/FetchMaintenanceModeRedirector';
import { SuperTokensWrapper } from 'supertokens-auth-react';

import { CookieConsentsOverlay } from './markup/Element/CookieConsentsOverlay';

function App() {
  return (
    <SuperTokensWrapper>
      <CookieConsentsOverlay />
      <div className="App">
        <FetchMaintenanceModeRedirector />
        <FetchUnathorizedInterceptor />
        <FetchLoadingIndicator />
        <Markup />      
        <ToastContainer />
      </div>
    </SuperTokensWrapper>
  );
}

export default App;
