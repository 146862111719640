import React,{Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import './TermsOfService.css';

var bnr = require('./../../images/main-slider/slide1.jpg');

class TermsOfService_01_10_2024 extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />
                <div className="page-content">				
                    <div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry align-m text-white">
                                <div className="col-lg-12 col-md-12 col-sm-12 error-page text-center bg-black">
                                    <hr />
                                    <h2 className="text-secondry text-uppercase">Regulamin Serwisu Internetowego</h2>
                                    <h2 className="text-secondry text-uppercase">all-it.jobs</h2>
                                    <span className='text-secondry text-lowercase'>(Wersja z dnia 01.10.2024 r.)</span>
                                    <hr />
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 1 Postanowienia ogólne</span>
                                        </p>
                                        <p>
                                            <div>1.1. Niniejszy regulamin określa zasady korzystania z serwisu internetowego dostępnego pod adresem all-it.jobs (dalej: „Serwis”).</div>
                                            <div>1.2. Serwis jest prowadzony przez QuadSoft Rafał Kozłowski, z siedzibą we Wrocławiu, ul. Starodębowa 24, NIP: PL8951810576, REGON: 021009401.</div>
                                            <div>1.3. Serwis pełni funkcję agregatora ofert pracy, pozyskującego oferty z różnych zewnętrznych serwisów (job boardów). Serwis umożliwia Użytkownikom przeglądanie ofert pracy, informując jednocześnie o źródle, z którego dana oferta pochodzi oraz udostępniający inne funkcje ułatwiające poszukiwanie pracy.</div>
                                            <div>1.4. Niniejszy regulamin obowiązuje wszystkich Użytkowników Serwisu. Rejestracja oraz korzystanie z Serwisu oznacza akceptację postanowień regulaminu.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 2 Definicje</span>
                                        </p>
                                        <p>
                                            <div>2.1. <span className='text-weight-bold'>Serwis</span> – platforma internetowa agregująca głównie oferty pracy z różnych zewnętrznych serwisów oraz świadcząca inne usługi związane z tematyką znajdowania pracy.</div>
                                            <div>2.2. <span className='text-weight-bold'>Użytkownik</span> – osoba fizyczna, która korzysta z funkcjonalności Serwisu, w tym przegląda oferty pracy, rejestruje się, loguje do Serwisu, tworzy Profil oraz inne.</div>
                                            <div>2.3. <span className='text-weight-bold'>Profil</span> – konto Użytkownika w Serwisie, umożliwiające zapisanie historii aplikacji na oferty pracy oraz przechowywanie informacji, w tym CV oraz inne.</div>
                                            <div>2.4. <span className='text-weight-bold'>Oferta pracy</span> – oferta zatrudnienia, która została zindeksowana przez Serwis i pochodzi z zewnętrznych źródeł, o których Użytkownik jest informowany.</div>
                                            <div>2.5. <span className='text-weight-bold'>CV</span> – życiorys zawodowy Użytkownika, który może być dodany do Profilu w celu rejestrowania aplikacji na oferty pracy.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 3 Rejestracja i logowanie</span>
                                        </p>
                                        <p>
                                            <div>3.1. Rejestracja w Serwisie jest dobrowolna, jednakże niektóre funkcjonalności Serwisu są dostępne tylko dla zarejestrowanych Użytkowników.</div>
                                            <div>3.2. Użytkownik może zarejestrować się w Serwisie poprzez podanie adresu e-mail (poprzez uzyskanie kodu One Time Password) lub zarejestrowanie się poprzez jeden z dostępnych serwisów social media.</div>
                                            <div>3.3. Użytkownik ponosi pełną odpowiedzialność za prawdziwość i aktualność podanych danych.</div>
                                            <div>3.4. Użytkownik zobowiązuje się do zachowania poufności swojego hasła i nieudostępniania go osobom trzecim.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 4 Korzystanie z funkcjonalności Serwisu</span>
                                        </p>
                                        <p>
                                            <div>4.1. Serwis agreguje oferty pracy z zewnętrznych job boardów i prezentuje je Użytkownikom. Przy każdej ofercie znajduje się informacja o źródle jej pochodzenia.</div>
                                            <div>4.2. Użytkownik może oznaczyć w swoim Profilu, że zaaplikował na wybraną ofertę pracy.</div>
                                            <div>4.3. Użytkownik ma możliwość załączenia swojego CV do informacji o ofercie, na którą zaaplikował. Przesłane CV przechowywane jest w ramach Profilu Użytkownika.</div>
                                            <div>4.4. Serwis nie ponosi odpowiedzialności za treść ofert pracy, które pochodzą z zewnętrznych źródeł. Wszelkie roszczenia dotyczące ofert pracy powinny być kierowane bezpośrednio do źródła, z którego pochodzi oferta.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 5 Ochrona danych osobowych</span>
                                        </p>
                                        <p>
                                            <div>5.1. Administratorem danych osobowych Użytkowników jest QuadSoft Rafał Kozłowski, z siedzibą we Wrocławiu, ul. Starodębowa 24.</div>
                                            <div>5.2. Dane osobowe Użytkowników przetwarzane są w celu realizacji usług oferowanych przez Serwis, w tym rejestracji konta, przechowywania historii aplikacji oraz CV.</div>
                                            <div>5.3. Użytkownik ma prawo dostępu do swoich danych, ich sprostowania, usunięcia oraz ograniczenia przetwarzania, jak również prawo do przenoszenia danych.</div>
                                            <div>5.4. Dane osobowe mogą być udostępniane podmiotom trzecim wyłącznie na podstawie zgody Użytkownika lub przepisów prawa.</div>
                                            <div>5.5. Szczegóły dotyczące przetwarzania danych osobowych zostały opisane w Polityce Prywatności.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 6 Zgody marketingowe i powiadomienia</span>
                                        </p>
                                        <p>
                                            <div>6.1. W trakcie rejestracji, Użytkownik ma możliwość wyrażenia zgody na:</div>
                                            <div>a) otrzymywanie informacji o nowych funkcjonalnościach Serwisu,</div>
                                            <div>b) otrzymywanie ofert pracy dopasowanych do jego profilu zawodowego i filtrów, na podstawie analizy jego aktywności oraz załączonych CV, w tym za pomocą algorytmów AI.</div>
                                            <div>6.2. Zgody te są dobrowolne, a Użytkownik ma prawo w dowolnym momencie wycofać swoją zgodę poprzez ustawienia swojego Profilu lub kontakt z Administratorem.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 7 Płatności i plany subskrypcyjne</span>
                                        </p>
                                        <p>
                                            <div>7.1. Korzystanie z Serwisu jest obecnie bezpłatne, jednakże Administrator zastrzega sobie prawo do wprowadzenia opłat za wybrane usługi lub funkcjonalności w przyszłości.</div>
                                            <div>7.2. W przypadku wprowadzenia opłat, Administrator zapewni Użytkownikom dostęp do odpowiednich informacji dotyczących planów subskrypcyjnych, kosztów korzystania z płatnych usług oraz dostępnych metod płatności, które będą opublikowane na stronie Serwisu.</div>
                                            <div>7.3. Administrator z wyprzedzeniem poinformuje Użytkowników o wprowadzeniu jakichkolwiek opłat lub zmian w zakresie dostępnych usług poprzez odpowiednią komunikację na stronie Serwisu oraz, w miarę możliwości, drogą elektroniczną.</div>
                                            <div>7.4. Użytkownicy, którzy nie zaakceptują nowych warunków dotyczących płatności, będą mieli możliwość zakończenia korzystania z Serwisu bez ponoszenia jakichkolwiek opłat.</div>
                                            <div>7.5. Administrator może wprowadzać promocje, zniżki lub okresy testowe dla wybranych usług, o czym Użytkownicy będą informowani za pośrednictwem strony Serwisu.</div>
                                        </p>
                                    </p>
                                    <p className='text-justify'>                                      
                                        <p>
                                            <span className='text-weight-bold '>§ 8 Postanowienia końcowe</span>
                                        </p>
                                        <p>
                                            <div>8.1. Niniejszy regulamin obowiązuje od dnia 01.10.2024.</div>
                                            <div>8.2. Administrator zastrzega sobie prawo do zmiany regulaminu w dowolnym czasie, informując Użytkowników o zmianach z odpowiednim wyprzedzeniem.</div>
                                            <div>8.3. W sprawach nieuregulowanych w regulaminie mają zastosowanie przepisy prawa polskiego, w tym ustawy o ochronie danych osobowych oraz RODO.</div>
                                        </p>
                                    </p>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>	
                
                <Footer />
            </div>
        )
    }
}

export default TermsOfService_01_10_2024;